import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {change, getFormValues, reduxForm} from 'redux-form'
import {
    CHANGE_PERSONAL_INFOS,
    postChangePersonalInfos
} from '../../../../../redux/actions/app/personal_infos/personal_infos.actions'
import {changePersonalInfosLink} from '../../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import ChangePersonalInfos from "./ChangePersonalInfos";

const mapStateToProps = (state, ownProps) => {
    return {
        loading: getLoadingEntity(state, CHANGE_PERSONAL_INFOS),
        uri: changePersonalInfosLink(state),
        tiers: ownProps.location.state.tiers,
        formValues: getFormValues('changePersonalInfosForm')(state),
        initialValues: {
            'tel_mobile': ownProps.location.state.tiers.address_phone,
            'email': ownProps.location.state.tiers.address_mail,
            'email_confirmation': ownProps.location.state.tiers.address_mail,
            'cp': ownProps.location.state.tiers.address_postalcode ?? null,
            'ville': ownProps.location.state.tiers.address_city	 ?? null,
            'addresse1': ownProps.location.state.tiers.address_street_1	 ?? null,
}
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    changeValue: (field, value) => change(`changePersonalInfosForm`, field, value),
    submitPostChangePersonalInfos: ({form, query}) => postChangePersonalInfos({form, query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostChangePersonalInfos} = dispatchProps
    const {uri, tiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            form.tiers = tiers.belair_id
            return submitPostChangePersonalInfos({form, query: uri})
        }
    }
}
const ChangePasswordContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'changePersonalInfosForm'
    })
)(ChangePersonalInfos)

export default ChangePasswordContainer
