import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import {
    tiersApporteurUpdateGetLink
} from '../../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import {
    POST_UPDATE_TIERS_APPORTEUR,
    postUpdateTiersApporteur
} from "../../../../../redux/actions/app/tiers/tiers.actions";
import ChangeMarqueBlanche from "./ChangeMarqueBlanche";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    return {
        loading: getLoadingEntity(state, POST_UPDATE_TIERS_APPORTEUR),
        uri: tiersApporteurUpdateGetLink(state),
        tiers: ownProps.location.state.tiers,
        initialValues: {
            'idmarqueblancheform': ownProps.location.state.tiers.marque_blanche_form,
            'idmarqueblancheeditique': ownProps.location.state.tiers.marque_blanche_editique,
        },
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostUpdateTiersApporteur: ({form, query}) => postUpdateTiersApporteur({form, query})
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostUpdateTiersApporteur} = dispatchProps
    const {uri, tiers} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            return submitPostUpdateTiersApporteur({form, query: _.replace(uri, '{id}', tiers.belair_id)})
        }
    }
}
const ChangeMarqueBlancheContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'change_mb'
    })
)(ChangeMarqueBlanche)

export default ChangeMarqueBlancheContainer
