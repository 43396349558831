import React from 'react';
import {Link} from "react-router-dom";

const ProductItem = (props) => {
    const {index, idTiers, product} = props

    return (
        <tr key={index}>
            <td>{product.name}</td>
            <td>{product.actif ? 'Activé' : 'Désactivé'}</td>
            <td><Link to={{
                    pathname: `/tiers/${idTiers}/produit/${product.id}`,
                    state: {
                        product: product
                    }
                }}  className={'btn btn-edit'}>&nbsp;</Link>
            </td>
        </tr>
    );
};

export default ProductItem;