import React, {Fragment} from 'react'
import ButtonsContainer from "../Buttons/ButtonsContainer";
import {redirectInNewWindow} from "../../../../../utils/function";
import CommerciauxContainer from "./Commerciaux/CommerciauxContainer";
import ApporteursContainer from "./Apporteurs/ApporteursContainer";
import CorrespondantsContainer from "./Correspondants/CorrespondantsContainer";
import StatsContainer from "./Stats/StatsContainer";
import _ from "lodash";
import {openInWindow} from "../../../../../constants/constants";
import ReclamationsContainer from "../../Reclamations/ReclamationsContainer";
import ChequesCadeauxContainer from "./ChequesCadeaux/ChequesCadeauxContainer";
import CommissionsContainer from "./Commissions/CommissionsContainer";
import AccidentsContainer from "./Accidents/AccidentsContainer";
import InfoCourtierContainer from "./InfoCourtier/InfoCourtierContainer";
import ComptePaddockContainer from "./ComptePaddock/ComptePaddockContainer";
import ProductsContainer from "./Products/ProductsContainer";
import Badge from "../../../../Commun/Badge/Badge";

const Courtier = (props) => {
    const {tiers, permissions, roles, postUpdateTiersApporteur, query, querySyncApporteur, postSyncApporteur} = props

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                    <Badge status={tiers.stadeGIE} classBadge={'align-text-top'}/>
                    {!_.includes(roles, 'gestionnaire televente') &&
                        <span className={'btn btn-cta btn-action float-right'} onClick={(e) => redirectInNewWindow({
                            e,
                            url: `/les-activites/tiers/${tiers.belair_id}`,
                            name: openInWindow.activitiesTiers
                        })}>Les activités du tiers</span>}
                </div>
                <div className={'col-12 mt-3'}>
                    <ButtonsContainer tiers={tiers}/>

                    <button className={'btn btn-cta btn-success mt-3 ml-0'}
                            onClick={() => {
                                if (window.confirm('Etes vous sûr de vouloir synchroniser les données avec Synergie ?')) postSyncApporteur({
                                    query: _.replace(querySyncApporteur, '{id}', tiers.belair_id),
                                })
                            }}
                        >
                        Synchroniser Synergie
                    </button>

                    {_.includes(roles, 'gestionnaire commercial') && (
                        <Fragment>
                            {tiers.stadeGIE === 'CL'
                                ? <button className={'btn btn-cta btn-success mt-3 ml-2'}
                                          onClick={() => {
                                              if (window.confirm('Attention, il faut penser à vérifier les données Salesforce ou la mise à jour automatique avant de réactiver l’apporteur manuellement')) postUpdateTiersApporteur({
                                                  query: _.replace(query, '{id}', tiers.belair_id),
                                                  form: {stadeGIE: 'VA'}
                                              })
                                          }}
                                >Ouvrir la fiche apporteur</button>
                                : <button className={'btn btn-cta btn-danger mt-3 ml-2'} onClick={() => {
                                    if (window.confirm('Attention, il faut penser à vérifier les données Salesforce ou la mise à jour automatique avant de clôturer l’apporteur manuellement')) postUpdateTiersApporteur({
                                        query: _.replace(query, '{id}', tiers.belair_id),
                                        form: {stadeGIE: 'CL'}
                                    })
                                }}>Clôturer la fiche apporteur</button>
                            }

                            <button className={'btn btn-cta btn-ternary mt-3 ml-2'} onClick={() => {
                                if (window.confirm(`Attention, en ${tiers.lock_update ? 'débloquant' : 'bloquant'} la MAJ Auto, les données de Salesforce ${tiers.lock_update ? 'mettront à jour la fiche apporteur automatiquement' : 'ne mettront plus à jour la fiche apporteur'}`)) {
                                    postUpdateTiersApporteur({
                                        query: _.replace(query, '{id}', tiers.belair_id),
                                        form: {lock_update: !tiers.lock_update}
                                    })
                                }
                            }}>
                                {tiers.lock_update ? 'Déblocage MAJ Auto' : 'Blocage MAJ Auto'}
                            </button>
                        </Fragment>
                    )}
                </div>
            </div>
            <div className="row margin-top">
                <div className="col-12">
                    <ComptePaddockContainer/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col-6'}>
                    <InfoCourtierContainer tiers={tiers} permissions={permissions}/>
                </div>
                <div className={'col-6'}>
                    <CommerciauxContainer id={tiers.belair_id} className={'loader'}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <CorrespondantsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>
            {_.includes(roles, 'gestionnaire commercial') && <div className={'row margin-top'}>
                <div className={'col'}>
                    <ProductsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>}
            {_.includes(permissions, 'read commissions') && <div className={'row margin-top'}>
                <div className={'col'}>
                    <CommissionsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>}
            {
                _.includes(tiers.type, ['M', 'D']) && <div className={'row margin-top'}>
                    <div className={'col'}>
                        <ChequesCadeauxContainer id={tiers.belair_id}/>
                    </div>
                </div>
            }

            <div className={'row margin-top'}>
                <div className={'col'}>
                    <StatsContainer id={tiers.belair_id} className={'loader'}/>
                </div>
            </div>
            {_.includes(tiers.type, '7') &&
                <div className={'row margin-top'}>
                    <div className={'col-6'}>
                        <ApporteursContainer id={tiers.belair_id} className={'loader'}/>
                    </div>
                </div>
            }
            <div className="row margin-top">
                <div className="col">
                    <ReclamationsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <AccidentsContainer className={'loader'}/>
                </div>
            </div>
        </Fragment>
    )
}

export default Courtier
