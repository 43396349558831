const validate = values => {
    const errors = {}

    const requiredFields = [
        'actif',
        'taux_com_def',
        'taux_com_max',
        'montant_fa_def',
        'montant_fa_max',
        'montant_fr_def',
        'montant_fr_max',
    ]

    requiredFields.forEach(field => {
        if (values[field] === undefined || values[field] === '') {
            errors[field] = 'Le champ est obligatoire'
        }
    })
    return errors
}

export default validate
