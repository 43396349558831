import axios from "axios";
import * as Sentry from '@sentry/react'
import {
    API_URL,
    GRAVITEE_API_KEY,
    GRAVITEE_URL,
    openInWindow,
    SERVICES_URL
} from "../constants/constants";
import moment from 'moment'
import _ from "lodash";
import {tokenUser} from "../redux/selectors/localStorage/localStorage";

/**
 * Get city by postal code
 * use
 * async fetchCityByPostalCode(value){
 *      let cities = await getCity(value)
 *      console.log(cities)
 * }
 * @param value
 * @returns {string}
 */
export const getCity = value => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/towns/${value}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Check iban
 * use
 * async fetchCheckIban(value){
 *      let iban = await checkIban(value)
 *      console.log(iban)
 * }
 * @param value
 * @returns {object}
 */
export const checkIban = (value) => {
    return new Promise(resolve => {
        axios({
            method: "GET",
            url: `${GRAVITEE_URL}/ibancheck/?iban=${value}`,
            headers: {
                "X-Gravitee-Api-Key": GRAVITEE_API_KEY
            },
        }).then((response) => {
            resolve(response.data);
        }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} GRAVITEE IBAN - ${error.message}`);
            }
        });
    });
}

/**
 * Get Address
 * use
 * async fetchAddress(value){
 *      let address = await getAddress(value)
 *      console.log(address)
 * }
 * @param value
 * @returns {object}
 */
export const getAddress = (value, validate = 'Undefined', localityId = null, postalCode = null, locality = null) => {
    return new Promise((resolve) => {

        const params = {
            value: encodeURIComponent(value),
            validate,
            localityId,
            postalCode,
            locality
        };

        const filteredParams = Object.entries(params)
            .filter(([key, val]) => val !== null)
            .map(([key, val]) => `${key}=${val}`)
            .join('&');

        const url = `${SERVICES_URL}/check-address?${filteredParams}`;

        axios.get(url)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    });
};

/**
 * Get city by search
 * use
 * async fetchCityBySearch(value){
 *      let cities = await getCity(value)
 *      console.log(cities)
 * }
 * @param value
 * @returns {string}
 */
export const getCityBySearch = value => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/towns/search/${value}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by postal code
 * use
 * async fetchCompagnys(value){
 *      let compagnys = await getCompagnys(value)
 *      console.log(compagnys)
 * }
 * @param value
 * @returns {string}
 */
export const getCompagnys = () => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/companys`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by postal code
 * use
 * async fetchInsurers(value){
 *      let compagnys = await getInsurers(value)
 *      console.log(compagnys)
 * }
 * @param value
 * @returns {string}
 */
export const getInsurers = () => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/insurers`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by postal code
 * use
 * async fetchInsurers(value){
 *      let compagnys = await getInsurers(value)
 *      console.log(compagnys)
 * }
 * @param value
 * @returns {string}
 */
export const getCountries = () => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/countries`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get city by value
 * use
 * async fetchCities(value){
 *      let cities = await getCitiesFromFrance(value)
 *      console.log(cities)
 * }
 * @param value
 * @returns [{}]
 */
export const getCitiesFromFrance = (value, request = "SearchBirthplaceWS") => {
    return new Promise(resolve => {
        axios.get(`${SERVICES_URL}/check-birth?value=${value}&function=${request}`)
            .then(response => {
                resolve(response.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} SERVICE - ${error.message}`);
            }
        })
    });
}

/**
 * Get reparateur by code postal
 * use
 * async fetchReparateurByCodePostal(postalCode){
 *      let reparateurs = await getReparateurByCodePostal(postalCode)
 *      console.log(reparateurs)
 * }
 * @param postalCode
 * @returns {string}
 */
export const getReparateurByCodePostal = postalCode => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/garages/${postalCode}`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get accident repairer
 * us
 * @returns {string}
 */
export const getReparateurAccident = () => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/garages-sinistre`)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}


/**
 * Get Courtiers
 * use
 * async fetchCourtiers(value){
 *      let courtiers = await getCourtiers(value)
 *      console.log(courtiers)
 * }
 * @param value
 * @returns {string}
 */
export const getCourtiers = () => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/courtiers`, {headers: {'Authorization':`Bearer ${tokenUser.trim()}`}})
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}


/**
 * Get Courtiers
 * use
 * async fetchCourtiers(value){
 *      let courtiers = await getCourtiers(value)
 *      console.log(courtiers)
 * }
 * @param value
 * @returns {string}
 */
export const getCourtiersAppex = () => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/courtiers-appex`, {headers: {'Authorization':`Bearer ${tokenUser.trim()}`}})
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get Courtiers
 * use
 * async fetchCourtiers(value){
 *      let courtiers = await getCourtiers(value)
 *      console.log(courtiers)
 * }
 * @param value
 * @returns {string}
 */
export const getCourtierLink = id => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/courtiers/${id}/links`, {headers: {'Authorization':`Bearer ${tokenUser.trim()}`}})
            .then(response => {
                resolve(response.data.links)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * Get Courtiers
 * use
 * async fetchCourtiers(value){
 *      let courtiers = await getCourtiers(value)
 *      console.log(courtiers)
 * }
 * @param value
 * @returns {string}
 */
export const getContractsCourtiers = id => {
    return new Promise(resolve => {
        axios.get(`${API_URL}/tiers/${id}/contracts`, {headers: {'Authorization':`Bearer ${tokenUser.trim()}`}})
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
            console.log(error)
            if (error.code !== 404){
                Sentry.captureMessage(`${error.code} PASS - ${error.message}`);
            }
        })
    });
}

/**
 * retourne les jours férié de l'année
 * use
 * JoursFeries(moment().weekYear())
 * @param année
 * @returns {moment Object}
 */
export const JoursFeries = an => {

    const JourAn = moment(`${an}-01-01`).format('YYYY-MM-DD')
    const FeteTravail = moment(`${an}-05-01`).format('YYYY-MM-DD')
    const Victoire1945 = moment(`${an}-05-08`).format('YYYY-MM-DD')
    const FeteNationale = moment(`${an}-07-14`).format('YYYY-MM-DD')
    const Assomption = moment(`${an}-08-15`).format('YYYY-MM-DD')
    const Toussaint = moment(`${an}-11-01`).format('YYYY-MM-DD')
    const Armistice = moment(`${an}-11-11`).format('YYYY-MM-DD')
    const Noel = moment(`${an}-12-25`).format('YYYY-MM-DD')
    const SaintEtienne = moment(`${an}-12-26`).format('YYYY-MM-DD')

    const G = an % 19
    const C = Math.floor(an / 100)
    const H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30
    const I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11))
    const J = (an * 1 + Math.floor(an / 4) + I + 2 - C + Math.floor(C / 4)) % 7
    const L = I - J
    const MoisPaques = 3 + Math.floor((L + 40) / 44)
    const JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4)
    const PaquesOrigin = moment(`${an}-0${MoisPaques}-${JourPaques}`)

    const Paques = moment(`${an}-0${MoisPaques}-${JourPaques}`).format("YYYY-MM-DD")
    const VendrediSaint = moment(PaquesOrigin._i).subtract(2, 'days').format("YYYY-MM-DD")
    const LundiPaques = moment(PaquesOrigin._i).add(1, 'days').format("YYYY-MM-DD")
    const Ascension = moment(PaquesOrigin._i).add(39, 'days').format("YYYY-MM-DD")
    const Pentecote = moment(PaquesOrigin._i).add(49, 'days').format("YYYY-MM-DD")
    const LundiPentecote = moment(PaquesOrigin._i).add(50, 'days').format("YYYY-MM-DD")

    return [
        JourAn,
        VendrediSaint,
        Paques,
        LundiPaques,
        FeteTravail,
        Victoire1945,
        Ascension,
        Pentecote,
        LundiPentecote,
        FeteNationale,
        Assomption,
        Toussaint,
        Armistice,
        Noel,
        SaintEtienne
    ]
}

/**
 * retourne si un document de la tâche à été traité par KOFAX (ou le polonais)
 * @param filesList
 * @returns {Object or undefined}
 */
export const isVerifiedByKofax = filesList => {
    return _.find(filesList, file => {
        return file.fields.length > 0
    })
}

/**
 * Ouvre une nouvelle fenetre
 * @param url, targetName
 */
export const redirectInNewWindow = ({e, url, name}) => {
    e.preventDefault()
    window.open(
        `${window.location.origin}${url}`,
        `Window${name}AprilMoto`, 'toolbar=1,location=0,menubar=1,width=1470,height=670,resizable,scrollbars=yes,status=1,top=100'
    )
}

export const getBoolean = value => {
    return !!JSON.parse(String(value).toLowerCase())
}

/**
 * Format number to euro value
 * @param number
 * @param style
 * @returns {string}
 */
export const formatEuro = (number, style = 'currency') => {
    return new Intl.NumberFormat('fr-FR', {style, currency: 'EUR'}).format(number)
}

export const formatThousandSpace = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(/' '/g, '&nbsp;')
}

export const nouvelOnglet = () => {
    return _.includes(openInWindow, _.replace(window.name, /Window|AprilMoto/g, '')) || _.startsWith(window.name, 'EmailLibre', 6)
}

export const formatImmatriculation = value => {
    if (!value) {
        return value
    }

    const separateur = '-'

    const onlyNums = value.replace(/[!@#$%^&*\-(),.?":{}|<>]/g, '').toUpperCase()

    // Nouvelle plaque immatriculation : AA123AA ou AA123A ou C254L
    if (/^[a-zA-Z]{1,2} ?-?[0-9]{2,3} ?-?[a-zA-Z]{1,2}$/.test(value)) {
        return `${onlyNums.slice(0, 2)}${separateur}${onlyNums.slice(2, 5)}${separateur}${onlyNums.slice(5, 7)}`
    }

    // Ancienne plaque immatriculation : 1234AA12
    if (/^[0-9]{1,4} ?-?[a-zA-Z]{2,3} ?-?[0-9]{2}$/.test(value)) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 6)}${separateur}${onlyNums.slice(6, 8)}`
    }

    // Ancienne plaque corse : 1234AAA1A
    if (/^[0-9]{1,4} ?-?[a-zA-Z]{2,3} ?-?[0-9]{1}[a-bA-B]{1}$/.test(value)) {
        return `${onlyNums.slice(0, 4)}${separateur}${onlyNums.slice(4, 7)}${separateur}${onlyNums.slice(7, 9)}`
    }

    return value.slice(0, 11)

}
