import {connect} from 'react-redux'
import {compose} from "redux";
import EditProduct from "./EditProduct";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import _ from "lodash";
import {
    getProduct,
    PRODUCT
} from "../../../../../redux/actions/app/products/product.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {tiersProductGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getProductData} from "../../../../../redux/selectors/products/product.selector";

const mapStateToProps = (state, ownProps) => {
    const product = ownProps?.location?.state?.product || getProductData(state);

    return {
        loaded: getLoadingEntity(state, PRODUCT) === false || product !== undefined,
        uri: tiersProductGetLink(state),
        tiersId: ownProps.match.params.id,
        productId: ownProps.match.params.ids,
        product: product,
    }
}

const mapDispatchToProps = {
    getProduct,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, tiersId, productId, product} = stateProps
    const {getProduct} = dispatchProps
console.log(product);

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => !product ? getProduct({query: _.replace(_.replace(uri, '{id}', tiersId), '{ids}', productId)}) : null
    }
}

const EditProductContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(EditProduct)

export default EditProductContainer