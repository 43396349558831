import _ from 'lodash';
import { useEffect } from 'react';
import Client from './Client/Client';
import Compagnie from './Compagnie/Compagnie';
import CourtierContainer from './Courtier/CourtierContainer';
import Garage from './Garage/Garage';
import GarageContainer from "./Garage/GarageContainer";

const Tiers = ({ tiers, roles, idTiers, uri, getTiers, history }) => {
    useEffect(() => {
        const fetchData = () => {
            window.scrollTo(0, 0);
            if (tiers.belair_id !== idTiers) {
                getTiers({
                    query: _.replace(uri, '{id}', idTiers),
                    id: idTiers,
                });
            }
        };
        fetchData();
    }, [tiers.belair_id, idTiers, uri, getTiers]);

    const validTiers = [
        '1', '1P', '3', 'M', 'G', 'MG', '7', '72', '87', 'J', 'GM', 'MY', 'P', 'K', '7', '87', '72', '7U', '27', 'MG7',
        '78', '7F', '75', '7H', 'U7', 'R', 'D', 'V', '9', 'P', 'W',
    ];

    return (
        <div className={'tiers-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => history.go(-1)} className={'btn btn-return'}>
                        Retour
                    </button>
                </div>
            </div>
            {['P', '1', '1P', '3', 'V', '9', 'P', 'W'].includes(tiers.type) && <Client tiers={tiers} roles={roles} />}
            {['G', 'J', 'R', 'D'].includes(tiers.type) && <GarageContainer tiers={tiers} roles={roles} />}
            {['K'].includes(tiers.type) && <Compagnie tiers={tiers} roles={roles} />}
            {['7', 'M'].includes(tiers.type) && <CourtierContainer tiers={tiers} roles={roles} />}
            {!_.includes(validTiers, tiers.type) && (
                <p>
                    Aucun dossier ne correspond à la recherche du client. Merci de vérifier le numéro de tiers ou le
                    numéro de contrat.
                </p>
            )}
        </div>
    );
};

export default Tiers;
