import React from 'react'
import CallToAction from "../../../../../Commun/Button/CallToAction/CallToAction";
import {redirectInNewWindow} from "../../../../../../utils/function";
import {openInWindow} from "../../../../../../constants/constants";

const ButtonsCourtier = (props) => {
    const {tiers} = props

    return (
        <div className={'buttons'}>
            <CallToAction
                url={{pathname: `/tiers/${tiers.belair_id}/modifier-informations`, state: {tiers: tiers}}}
                action={'coordonnees'}
                toolText={'Modifier les coordonnées'}
            />
            <CallToAction
                action={'add-chrono'}
                toolText={'Ajouter un chrono / agenda'}
                onClick={(e) => redirectInNewWindow({
                    e,
                    url: `/tiers/${tiers.belair_id}/ajout-suivi`,
                    name: openInWindow.addActivities
                })}
            />
            <CallToAction
                url={{
                    pathname: `/tiers/${tiers.belair_id}/modifier-mb`,
                    state: { tiers: tiers },
                }}
                action={'mb'}
                toolText={'Modifier les marques blanches'}
            />
            <CallToAction
                url={{
                    pathname: `/tiers/${tiers.belair_id}/modifier-info-apporteur`,
                    state: { tiers: tiers },
                }}
                action={'edit-contract'}
                toolText={'Modifier les informations apporteur'}
            />
        </div>
    )
}

export default ButtonsCourtier
