export const TIERS = '[Tiers]';

export const GET_TIERS = `${TIERS} Get`;
export const SET_TIERS = `${TIERS} Set`;
export const GET_TIERS_BU = `${TIERS} Get Business Unit`;
export const SET_TIERS_BU = `${TIERS} Set Business Unit`;
export const GET_TIERS_BU_MDP = `${TIERS} Get Business Unit MDP`;
export const SET_TIERS_BU_MDP = `${TIERS} Set Business Unit MDP`;
export const POST_CREATE_TIERS = `${TIERS} Post`
export const POST_UPDATE_TIERS = `${TIERS} Update`
export const POST_UPDATE_BIRTH_INFOS_TIERS = `${TIERS} Update Birth Infos`
export const POST_TRACFIN_TIERS = `${TIERS} Post Tracfin`
export const POST_DELETE_TIERS = `${TIERS} Delete`
export const POST_UPDATE_TIERS_APPORTEUR = `${TIERS} Update Apporteur`
export const POST_DEMAT_STATUT = `${TIERS} Post Statut Demat`
export const POST_SYNC_APPORTEUR = `${TIERS} Post Sync Apporteur`


export const getTiers = ({query, id}) => ({
    type: GET_TIERS,
    payload: {
        data: query,
        otherData: {
            id: id
        }
    }
});

export const setTiers = ({data}) => ({
    type: SET_TIERS,
    payload: {data}
});

export const getTiersBusinessUnit = ({query}) => ({
    type: GET_TIERS_BU,
    payload: {
        data: query
    }
})

export const setTiersBusinessUnit = ({data}) => ({
    type: SET_TIERS_BU,
    payload: {data}
});

export const getTiersBUMdp = ({query, id}) => ({
    type: GET_TIERS_BU_MDP,
    payload: {
        data: query,
        body: {id}
    }
})

export const getTiersBusinessUnitPassword = ({data}) => ({
    type: SET_TIERS_BU_MDP,
    payload: {data}
})

export const postCreateTiers = ({query, form}) => ({
    type: POST_CREATE_TIERS,
    payload: {
        data: query,
        body: (form)
    }
})

export const postUpdateTiers = ({query, form}) => ({
    type: POST_UPDATE_TIERS,
    payload: {
        data: query,
        body: (form)
    }
})

export const postUpdateBirthInfosTiers = ({query, form}) => ({
    type: POST_UPDATE_BIRTH_INFOS_TIERS,
    payload: {
        data: query,
        body: (form)
    }
})

export const postTracfinTiers = ({query, form}) => ({
    type: POST_TRACFIN_TIERS,
    payload: {
        data: query,
        body: (form)
    }
})


export const postDeleteTiers = ({query, form}) => ({
    type: POST_DELETE_TIERS,
    payload: {
        data: query,
        body: (form)
    }
})

export const postUpdateTiersApporteur = ({query, form}) => ({
    type: POST_UPDATE_TIERS_APPORTEUR,
    payload: {
        data: query,
        body: (form)
    }
})
export const postDematStatut = ({query, form}) => ({
    type: POST_DEMAT_STATUT,
    payload: {
        data: query,
        body: form,
    }
})

export const postSyncApporteur = ({query, form}) => ({
    type: POST_SYNC_APPORTEUR,
    payload: {
        data: query,
        body: form,
    }
})
