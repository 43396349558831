import {connect} from 'react-redux'
import {compose} from "redux";
import Products from "./Products";
import {tiersProductsGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import _ from "lodash";
import loader from "../../../../../Commun/Spinner/Loader/Loader";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import {getProducts, PRODUCTS} from "../../../../../../redux/actions/app/products/products.actions";
import {getproductsData} from "../../../../../../redux/selectors/products/products.selector";

const mapStateToProps = state => {
    return {
        products: getproductsData(state),
        uri: tiersProductsGetLink(state),
        loaded: getLoadingEntity(state, PRODUCTS) === false,
        error: getErrorEntity(state, PRODUCTS)
    }
}

const mapDispatchToProps = {
    getProducts,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { getProducts } = dispatchProps
    const {uri} = stateProps
    const {id} = ownProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getProducts({query: _.replace(uri, '{id}', id)})
    }
}

const ProductsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Products)

export default ProductsContainer