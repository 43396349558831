import React from 'react';
import ProductItem from "./ProductItem/ProductItem";

const Products = (props) => {
    const {products, id} = props

    return (
        <div className={'bloc-info bloc-produits'}>
            <h2>Produits distribués</h2>
            <div className={'content row'}>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Nom du produit</th>
                        <th scope="col">Statut</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map((p, index) =>
                        <ProductItem key={index} product={p} {...props} idTiers={id}/>
                    )}
                    {products.length === 0 && <tr><td colSpan={3} className={'font-weight-normal'}>Aucun produit trouvé</td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Products;