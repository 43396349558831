import {
    setCorrespondants,
    POST_CORRESPONDANT,
    POST_UPDATE_CORRESPONDANT,
    POST_DELETE_CORRESPONDANT,
    CORRESPONDANT,
    GET_CORRESPONDANT,
    setCorrespondant
} from '../../../actions/app/correspondants/correspondants.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const correspondantMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_CORRESPONDANT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: CORRESPONDANT}),
                setLoader({state: true, entity: CORRESPONDANT})
            ])
            break

        case `${CORRESPONDANT} ${API_SUCCESS}`:
            next([
                setCorrespondant({correspondant: action.payload.data}),
                setError({state: false, entity: CORRESPONDANT}),
                setLoader({state: false, entity: CORRESPONDANT})
            ])
            break

        case `${CORRESPONDANT} ${API_ERROR}`:
            next([
                setError({state: true, entity: CORRESPONDANT}),
                setLoader({state: false, entity: CORRESPONDANT})
            ])
            break

        case POST_CORRESPONDANT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CORRESPONDANT,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_CORRESPONDANT})
            ])
            break

        case `${POST_CORRESPONDANT} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_CORRESPONDANT}),
                setLoader({state: false, entity: POST_CORRESPONDANT})
            ])

            window.location.pathname = `/tiers/${action.payload.meta.otherData.idTiers}`
            break

        case `${POST_CORRESPONDANT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_CORRESPONDANT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_CORRESPONDANT}),
                setLoader({state: false, entity: POST_CORRESPONDANT})
            ])
            break

        case POST_UPDATE_CORRESPONDANT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_CORRESPONDANT
                }),
                setLoader({state: true, entity: POST_UPDATE_CORRESPONDANT})
            ])
            break

        case `${POST_UPDATE_CORRESPONDANT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_UPDATE_CORRESPONDANT,
                    body: 'La modification du correspondant a été effectuée avec succès',
                    type: 'success',
                    title: 'Modification correspondant'
                }),
                setError({state: false, entity: POST_UPDATE_CORRESPONDANT}),
                setLoader({state: false, entity: POST_UPDATE_CORRESPONDANT})
            ])

            break

        case `${POST_UPDATE_CORRESPONDANT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_CORRESPONDANT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_CORRESPONDANT}),
                setLoader({state: false, entity: POST_UPDATE_CORRESPONDANT})
            ])
            break

        case POST_DELETE_CORRESPONDANT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DELETE_CORRESPONDANT
                }),
                setLoader({state: true, entity: POST_DELETE_CORRESPONDANT})
            ])
            break

        case `${POST_DELETE_CORRESPONDANT} ${API_SUCCESS}`:

            next([
                setCorrespondants({correspondants: action.payload.data}),
                setNotification({
                    entity: POST_DELETE_CORRESPONDANT,
                    body: `<p>Le correspondant a été supprimé avec succès</p>`,
                    type: 'success',
                    title: 'Correspondant Supprimé!'
                }),
                setLoader({state: false, entity: POST_DELETE_CORRESPONDANT}),
                setError({state: false, entity: POST_DELETE_CORRESPONDANT}),
            ])
            break

        case `${POST_DELETE_CORRESPONDANT} ${API_ERROR}`:

            next([
                setNotification({
                    entity: POST_DELETE_CORRESPONDANT,
                    body: `<p>Erreur lors de la suppression du correspondant : ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DELETE_CORRESPONDANT}),
                setLoader({state: false, entity: POST_DELETE_CORRESPONDANT})
            ])
            break

        default:
            break
    }
    return null
}
