import {connect} from 'react-redux'
import {compose} from "redux";
import EditProductForm from "./EditProductForm";
import {reduxForm} from "redux-form";
import validate from "./validate";
import _ from "lodash";
import {
    POST_UPDATE_PRODUCT,
    postUpdateProduct
} from "../../../../../../redux/actions/app/products/product.actions";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {
    tiersProductEditGetLink
} from "../../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = (state, ownProps) => {
    const product = ownProps.product;

    return {
        uri_update: tiersProductEditGetLink(state),
        loading: getLoadingEntity(state, POST_UPDATE_PRODUCT),
        tiersId: ownProps.match.params.id,
        productId: ownProps.match.params.ids,
        product: product,
        initialValues: {
            actif: product.actif,
            taux_com_def: product.taux_com_def,
            taux_com_max: product.taux_com_max,
            montant_fa_def: product.montant_fa_def,
            montant_fa_max: product.montant_fa_max,
            montant_fr_def: product.montant_fr_def,
            montant_fr_max: product.montant_fr_max,
        }
    }
}

const mapDispatchToProps = {
    postUpdateProduct
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri_update, tiersId, productId} = stateProps
    const {postUpdateProduct} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postUpdateProduct({
            query: _.replace(_.replace(uri_update, '{id}', tiersId), '{ids}', productId),
            form
        })
    }
}

const EditProductFormContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'edit_product',
        validate
    }),
)(EditProductForm)

export default EditProductFormContainer