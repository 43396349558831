import {connect} from 'react-redux'
import Garage from "./Garage";
import {getUserPermissions} from "../../../../../redux/selectors/user/user.selectors";
import {
    tiersApporteurSyncGetLink,
    tiersApporteurUpdateGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {postSyncApporteur, postUpdateTiersApporteur} from "../../../../../redux/actions/app/tiers/tiers.actions";
import {compose} from "redux";

const mapStateToProps = (state) => {
    return {
        permissions: getUserPermissions(state),
        query: tiersApporteurUpdateGetLink(state),
        querySyncApporteur: tiersApporteurSyncGetLink(state),
    }
}


const mapDispatchToProps = {
    postUpdateTiersApporteur,
    postSyncApporteur
}


const GarageContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
)(Garage)

export default GarageContainer
