import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {GET_PRODUCT, PRODUCT, setProduct} from "../../../actions/app/products/product.actions";

export const productMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_PRODUCT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: PRODUCT}),
                setLoader({state: true, entity: PRODUCT})
            ])
            break

        case `${PRODUCT} ${API_SUCCESS}`:
            next([
                setProduct({product: action.payload.data}),
                setError({state: false, entity: PRODUCT}),
                setLoader({state: false, entity: PRODUCT})
            ])
            break

        case `${PRODUCT} ${API_ERROR}`:
            next([
                setError({state: true, entity: PRODUCT}),
                setLoader({state: false, entity: PRODUCT})
            ])
            break

        default:
            break
    }
    return null
}
