import {memo, useEffect, useState} from 'react';
import { Field } from 'redux-form';
import { useAddress, useTownByZipCode } from '../../../../../api/services';
import { normalizeAndUppercase } from '../../../../../utils/normalizeInputs';
import CheckboxFieldRedux from '../../../../Commun/Input/Checkbox/CheckboxFieldRedux';
import SelectFieldRedux from '../../../../Commun/Input/Select/SelectFieldRedux';
import TextFieldRedux from '../../../../Commun/Input/Text/TextFieldRedux';

const AddressFields =  memo(function AddressFields({
    nameAddress1 = 'addresse1',
    nameAddress2 = 'addresse2',
    nameAddress3 = 'addresse3',
    nameCp = 'cp',
    nameVille = 'ville',
    formValues,
    changeValue,
}) {
    const [isSelectedAddress, setIsSelectedAddress] = useState(false);
    const { data: dataTowns, isFetched } = useTownByZipCode(formValues?.cp);
    const {
        data: dataAddress,
        isLoading: isLoadingAddress,
        isFetched: isFetchedAddress,
    } = useAddress(formValues?.[nameCp], formValues?.[nameVille], formValues?.[nameAddress1], formValues?.notFind);

    const onlyValidAddress = dataAddress?.filter((address) => address.postalCode === formValues?.cp);

    useEffect(() => {
        if (isFetched && dataTowns?.data?.length === 1) {
            changeValue(nameVille, dataTowns?.data[0]);
        }
    }, [isFetched, dataTowns, formValues?.[nameCp], formValues?.[nameVille]]);

    return (
        <>
            <div className="row">
                <div className="col">
                    <Field
                        type="number"
                        name={nameCp}
                        component={TextFieldRedux}
                        label={'Code postal (ex: 75000)'}
                        onChange={(e) => {
                            changeValue(nameVille, null);
                            changeValue(nameAddress1, null);
                            changeValue(nameAddress2, null);
                            changeValue(nameAddress3, null);
                            setIsSelectedAddress(false);
                        }}
                    />
                </div>
                <div className="col">
                    <Field
                        name={nameVille}
                        component={SelectFieldRedux}
                        label={'Ville'}
                        normalize={normalizeAndUppercase}
                        disabled={!formValues?.[nameCp]}
                        onChange={(e) => {
                            changeValue(nameAddress1, null);
                            changeValue(nameAddress2, null);
                            changeValue(nameAddress3, null);
                            setIsSelectedAddress(false);
                        }}
                    >
                        <option value={''} disabled>
                            Choisir une ville
                        </option>
                        {dataTowns?.data &&
                            dataTowns?.data.map((town) => (
                                <option key={town} value={town}>
                                    {town}
                                </option>
                            ))}
                    </Field>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col">
                    <Field
                        name={nameAddress1}
                        component={TextFieldRedux}
                        label={'Adresse (n° et rue)'}
                        disabled={!formValues?.[nameVille]}
                        normalize={normalizeAndUppercase}
                        classNameGroup={!formValues?.notFind ? 'mb-0' : ''}
                        onChange={(e) => setIsSelectedAddress(false)}
                    />
                    {formValues?.notFind ? (
                        <>
                            <Field
                                name={nameAddress2}
                                component={TextFieldRedux}
                                label={"Complément d'adresse : (Ex: Appartement 25)"}
                                disabled={!formValues?.[nameVille]}
                            />
                            <Field
                                name={nameAddress3}
                                component={TextFieldRedux}
                                label={'Batiment, escalier, étages : (Ex: ENTREE F BATIMENT 5)'}
                                disabled={!formValues?.[nameVille]}
                            />
                        </>
                    ) : !formValues?.[nameAddress1] && dataAddress?.length > 0 ? null : onlyValidAddress &&
                      onlyValidAddress.length > 0 &&
                      !isLoadingAddress ? (
                        !isSelectedAddress &&
                        isFetchedAddress &&
                        onlyValidAddress.map((address, i) => (
                            <div
                                key={i}
                                onClick={() => {
                                    changeValue(nameAddress1, address.additionalInfo_1);
                                    setIsSelectedAddress(true);
                                }}
                                className={'list-group'}
                            >
                                <p className={'pointer m-0 list-group-item list-group-item-action'}>
                                    {address.additionalInfo_1} - {address?.additionalInfo_2}
                                </p>
                            </div>
                        ))
                    ) : isLoadingAddress ? (
                        <div className={'list-group'}>
                            <p className={'m-0 list-group-item list-group-item-action'}>Chargement...</p>
                        </div>
                    ) : dataAddress?.length > 0 ? (
                        <div className={'list-group'}>
                            <p className={'m-0 list-group-item list-group-item-action'}>
                                Aucune adresse trouvée (Vérifier le code postal ou la ville)
                            </p>
                        </div>
                    ) : null}
                </div>
            </div>

            <Field
                name="notFind"
                component={CheckboxFieldRedux}
                label={"Je ne trouve pas l'adresse"}
                type={'checkbox'}
                onChange={(e) => changeValue('notFind', e.target.checked)}
            />
        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.formValues === nextProps.formValues;
});

export default AddressFields;
