import {
    TIERS,
    GET_TIERS,
    setTiers,
    GET_TIERS_BU,
    setTiersBusinessUnit,
    GET_TIERS_BU_MDP,
    POST_CREATE_TIERS,
    POST_UPDATE_TIERS,
    POST_UPDATE_BIRTH_INFOS_TIERS,
    POST_TRACFIN_TIERS,
    POST_DELETE_TIERS,
    POST_UPDATE_TIERS_APPORTEUR,
    POST_DEMAT_STATUT,
    POST_SYNC_APPORTEUR
} from '../../../actions/app/tiers/tiers.actions'

import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {logAction} from "../../../actions/core/logs/logs.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import Swal from 'sweetalert2'
import {POST_VEHICLE_TRANSFER} from "../../../actions/app/accidents/accidents.actions";

export const tiersMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_TIERS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: TIERS}),
                setLoader({state: true, entity: TIERS}),
                logAction({type: 'SHOW_TIERS', entity: TIERS, tiers: action.payload.otherData.id})
            ])
            break

        case `${TIERS} ${API_SUCCESS}`:
            next([
                setTiers({data: action.payload.data}),
                setError({state: false, entity: TIERS}),
                setLoader({state: false, entity: TIERS})
            ])
            break

        case `${TIERS} ${API_ERROR}`:
            next([
                setError({state: true, entity: TIERS}),
                setLoader({state: false, entity: TIERS})
            ])
            break

        case GET_TIERS_BU:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: GET_TIERS_BU}),
                setLoader({state: true, entity: GET_TIERS_BU})
            ])
            break

        case `${GET_TIERS_BU} ${API_SUCCESS}`:
            next([
                setTiersBusinessUnit({data: action.payload.data}),
                setError({state: false, entity: GET_TIERS_BU}),
                setLoader({state: false, entity: GET_TIERS_BU})
            ])
            break

        case `${GET_TIERS_BU} ${API_ERROR}`:
            next([
                setError({state: true, entity: GET_TIERS_BU}),
                setLoader({state: false, entity: GET_TIERS_BU})
            ])
            break

        case GET_TIERS_BU_MDP:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: GET_TIERS_BU_MDP
                }),
                setLoader({state: true, entity: GET_TIERS_BU_MDP})
            ])
            break

        case `${GET_TIERS_BU_MDP} ${API_SUCCESS}`:
            Swal.fire({
                title: action.payload.data.password,
                text: 'Le nouveau mot de passe à communiquer',
                icon: 'success',
                confirmButtonText: 'Terminer',
                confirmButtonColor: '#3085d6'
            })
            next([
                setError({state: false, entity: GET_TIERS_BU_MDP}),
                setLoader({state: false, entity: GET_TIERS_BU_MDP})
            ])
            break

        case `${GET_TIERS_BU_MDP} ${API_ERROR}`:
            next([
                setError({state: true, entity: GET_TIERS_BU_MDP}),
                setLoader({state: false, entity: GET_TIERS_BU_MDP})
            ])
            break

        case POST_CREATE_TIERS:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_CREATE_TIERS
                }),
                setLoader({state: true, entity: POST_CREATE_TIERS})
            ])
            break

        case `${POST_CREATE_TIERS} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_VEHICLE_TRANSFER,
                    body: 'La création du tiers a été effectuée avec succès',
                    type: 'success',
                    title: 'Création du tiers'
                }),
                setError({state: false, entity: POST_CREATE_TIERS}),
                setLoader({state: false, entity: POST_CREATE_TIERS})
            ])
            break

        case `${POST_CREATE_TIERS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_CREATE_TIERS,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_CREATE_TIERS}),
                setLoader({state: false, entity: POST_CREATE_TIERS})
            ])
            break

        case POST_UPDATE_TIERS:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_TIERS
                }),
                setLoader({state: true, entity: POST_UPDATE_TIERS})
            ])
            break

        case `${POST_UPDATE_TIERS} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_VEHICLE_TRANSFER,
                    body: 'La modification du tiers adverse a été effectuée avec succès',
                    type: 'success',
                    title: 'Modification du tiers'
                }),
                setError({state: false, entity: POST_UPDATE_TIERS}),
                setLoader({state: false, entity: POST_UPDATE_TIERS})
            ])

            break

        case `${POST_UPDATE_TIERS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_TIERS,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_TIERS}),
                setLoader({state: false, entity: POST_UPDATE_TIERS})
            ])
            break

        case POST_UPDATE_BIRTH_INFOS_TIERS:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_BIRTH_INFOS_TIERS
                }),
                setLoader({state: true, entity: POST_UPDATE_BIRTH_INFOS_TIERS})
            ])
            break

        case `${POST_UPDATE_BIRTH_INFOS_TIERS} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_UPDATE_BIRTH_INFOS_TIERS,
                    body: 'La modification des informations a été effectuée avec succès',
                    type: 'success',
                    title: 'Modification du tiers'
                }),
                setError({state: false, entity: POST_UPDATE_BIRTH_INFOS_TIERS}),
                setLoader({state: false, entity: POST_UPDATE_BIRTH_INFOS_TIERS})
            ])

            break

        case `${POST_UPDATE_BIRTH_INFOS_TIERS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_BIRTH_INFOS_TIERS,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_BIRTH_INFOS_TIERS}),
                setLoader({state: false, entity: POST_UPDATE_BIRTH_INFOS_TIERS})
            ])
            break

        case POST_TRACFIN_TIERS:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_TRACFIN_TIERS
                }),
                setLoader({state: true, entity: POST_TRACFIN_TIERS})
            ])
            break

        case `${POST_TRACFIN_TIERS} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_TRACFIN_TIERS,
                    body: 'La modification Tracfin a été effectuée avec succès',
                    type: 'success',
                    title: 'Modification du tiers'
                }),
                setError({state: false, entity: POST_TRACFIN_TIERS}),
                setLoader({state: false, entity: POST_TRACFIN_TIERS})
            ])

            break

        case `${POST_TRACFIN_TIERS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_TRACFIN_TIERS,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_TRACFIN_TIERS}),
                setLoader({state: false, entity: POST_TRACFIN_TIERS})
            ])
            break

        case `${POST_DELETE_TIERS}`:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'DELETE',
                    url: action.payload.data,
                    entity: POST_DELETE_TIERS
                }),
                setLoader({state: true, entity: POST_DELETE_TIERS})
            ])
            break

        case `${POST_DELETE_TIERS} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DELETE_TIERS,
                    body: "La suppression espace client a été effectuée avec succès",
                    type: 'success',
                    title: 'Suppression de l\'espace client'
                }),
                setError({state: false, entity: POST_DELETE_TIERS}),
                setLoader({state: false, entity: POST_DELETE_TIERS})
            ])

            break

        case `${POST_DELETE_TIERS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DELETE_TIERS,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DELETE_TIERS}),
                setLoader({state: false, entity: POST_DELETE_TIERS})
            ])
            break

        case POST_UPDATE_TIERS_APPORTEUR:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_TIERS_APPORTEUR
                }),
                setLoader({state: true, entity: POST_UPDATE_TIERS_APPORTEUR})
            ])
            break

        case `${POST_UPDATE_TIERS_APPORTEUR} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_UPDATE_TIERS_APPORTEUR,
                    body: 'La modification a été effectuée avec succès',
                    type: 'success',
                    title: 'Modification de l\'apporteur'
                }),
                setError({state: false, entity: POST_UPDATE_TIERS_APPORTEUR}),
                setLoader({state: false, entity: POST_UPDATE_TIERS_APPORTEUR})
            ])

            break

        case `${POST_UPDATE_TIERS_APPORTEUR} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_TIERS_APPORTEUR,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data.message}</p>`,
                }),
                setError({state: true, entity: POST_UPDATE_TIERS_APPORTEUR}),
                setLoader({state: false, entity: POST_UPDATE_TIERS_APPORTEUR})
            ])
            break

        case `${POST_DEMAT_STATUT}`:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DEMAT_STATUT
                    }),
                setLoader({state: true, entity: POST_DEMAT_STATUT})
            ])
            break

        case `${POST_DEMAT_STATUT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DEMAT_STATUT,
                    body: "Le status de la dématéralisation a été mis à jour",
                    type: 'success',
                    title: 'Mise à jour dématéralisation'
                }),
                setError({state: false, entity: POST_DEMAT_STATUT}),
                setLoader({state: false, entity: POST_DEMAT_STATUT}),
            ])
            window.location.reload()

            break

        case `${POST_DEMAT_STATUT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DEMAT_STATUT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DEMAT_STATUT}),
                setLoader({state: false, entity: POST_DEMAT_STATUT})
            ])
            break

        case `${POST_SYNC_APPORTEUR}`:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_SYNC_APPORTEUR
                    }),
                setLoader({state: true, entity: POST_SYNC_APPORTEUR})
            ])
            break

        case `${POST_SYNC_APPORTEUR} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_SYNC_APPORTEUR,
                    type: 'success',
                    title: action.payload.data.message ?? 'La demande a été envoyé a 6nergie'
                }),
                setError({state: false, entity: POST_SYNC_APPORTEUR}),
                setLoader({state: false, entity: POST_SYNC_APPORTEUR}),
            ])
            window.location.reload()

            break

        case `${POST_SYNC_APPORTEUR} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_SYNC_APPORTEUR,
                    body: `<p>Une erreur est survenue. ${action.payload.data.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_SYNC_APPORTEUR}),
                setLoader({state: false, entity: POST_SYNC_APPORTEUR})
            ])
            break

        default:
            break
    }
    return null
}
