import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {GET_PRODUCTS, PRODUCTS, setProducts} from "../../../actions/app/products/products.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_UPDATE_PRODUCT} from "../../../actions/app/products/product.actions";

export const productsMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_PRODUCTS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: PRODUCTS}),
                setLoader({state: true, entity: PRODUCTS})
            ])
            break

        case `${PRODUCTS} ${API_SUCCESS}`:
            next([
                setProducts({products: action.payload.data}),
                setError({state: false, entity: PRODUCTS}),
                setLoader({state: false, entity: PRODUCTS})
            ])
            break

        case `${PRODUCTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: PRODUCTS}),
                setLoader({state: false, entity: PRODUCTS})
            ])
            break

        case POST_UPDATE_PRODUCT:
            next([
                apiRequest({
                    body: action.payload.body,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_PRODUCT
                }),
                setLoader({state: true, entity: POST_UPDATE_PRODUCT})
            ])
            break

        case `${POST_UPDATE_PRODUCT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_UPDATE_PRODUCT,
                    body: 'La modification a été effectuée avec succès',
                    type: 'success',
                    title: 'Modification produit'
                }),
                setError({state: false, entity: POST_UPDATE_PRODUCT}),
                setLoader({state: false, entity: POST_UPDATE_PRODUCT})
            ])

            break

        case `${POST_UPDATE_PRODUCT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_PRODUCT,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data.message}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_PRODUCT}),
                setLoader({state: false, entity: POST_UPDATE_PRODUCT})
            ])
            break

        default:
            break
    }
    return null
}
