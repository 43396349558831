import {SET_PRODUCTS} from "../../actions/app/products/products.actions";

export const productsReducer = (products = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_PRODUCTS) {
        return payload
    } else {
        return products
    }
}
