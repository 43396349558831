export const PRODUCTS = '[Produits]'

export const GET_PRODUCTS = `${PRODUCTS} Get`
export const SET_PRODUCTS = `${PRODUCTS} Set`

export const getProducts = ({query}) => ({
    type: GET_PRODUCTS,
    payload: {
        data: query
    }
})

export const setProducts = ({products}) => ({
    type: SET_PRODUCTS,
    payload: {
        data: products
    },
    meta: {
        entity: PRODUCTS
    }
})
