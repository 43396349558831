import React, {Fragment} from 'react';
import EditProductFormContainer from "./EditProductForm/EditProductFormContainer";

const EditProduct = (props) => {
    const {product, history} = props

    return (
        <div className={'contract-container container'}>
            {
                (history.action && history.action !== 'POP') &&
                <div className={'row return-content'}>
                    <div className={'col'}>
                        <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                    </div>
                </div>
            }
            <Fragment>
                <div className={'row'}>
                    <div className={'col'}>
                        <h1 className={'title-primary'}>
                            Edition du produit {product.name}
                        </h1>
                    </div>
                </div>
            </Fragment>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <EditProductFormContainer {...props} product={product} />
                </div>
            </div>
        </div>
    );
};

export default EditProduct;