import React from 'react'
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import AddressFields from "../AddTiers/AddressFields";

const ChangePersonalInfos = (props) => {
    const {loading, handleSubmit, tiers, formValues, changeValue} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='tel_mobile'
                            component={TextFieldRedux}
                            label={'Téléphone mobile'}
                        />
                        <Field
                            name='email'
                            component={TextFieldRedux}
                            label={'Adresse email'}
                        />
                        <Field
                            name='email_confirmation'
                            component={TextFieldRedux}
                            label={'Confirmer l\'adresse email'}
                        />
                        <AddressFields formValues={formValues} changeValue={changeValue} />
                        <MyButton
                            variant='raised'
                            color='secondary'
                            size='large'
                            className={'btn btn-primary'}
                            disabled={loading}
                        >
                            {loading && <Spinner absolute/>}
                            Modifier les informations
                        </MyButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePersonalInfos
