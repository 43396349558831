import loader from "../../../../Commun/Spinner/Loader/Loader";
import { connect } from 'react-redux';
import { compose } from 'redux';
import {change, formValueSelector, reduxForm} from 'redux-form';
import _ from 'lodash';
import {
    contractDefaultGetLink,
    createFidelisationLink,
    tarifContractGetLink
} from '../../../../../redux/selectors/links/links.selectors';
import CreateFidelisation from './CreateFidelisation';
import validate from './validate';
import {
    CONTRACT,
    getContract,
    getTarifContract,
    TARIF_CONTRACT
} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData, getTarifContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    POST_FIDELISATIONS,
    postFidelisations
} from "../../../../../redux/actions/app/fidelisations/fidelisations.actions";
import {getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import moment from "moment";

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;
    const selector = formValueSelector('form-fidelisation');
    const contract = getContractData(state);

    return {
        idContract: id,
        roles: getUserRoles(state),
        uri: contractDefaultGetLink(state),
        contract,
        uri_tarif: tarifContractGetLink(state),
        tarif: getTarifContractData(state),
        edit: false,
        loading_tarif: getLoadingEntity(state, TARIF_CONTRACT) === true,
        loaded: getLoadingEntity(state, CONTRACT) === false,
        formValues: selector(state, 'reponse', 'cas_usage', 'interlocuteur', 'type', 'gestes', 'gestes_attributes', 'accept_rdv'),
        uriCreateFidelisation: createFidelisationLink(state),
        initialValues: {
            date_demande: moment(),
            eligible: Boolean(contract?.avenant_link_fid) === true ? 'T' : 'F',
            gestes_attributes: {
                J: {
                    montant_carte: '30'
                }
            }
        },
        errorForm: getErrorEntity(state, POST_FIDELISATIONS),
        loadingForm: getLoadingEntity(state, POST_FIDELISATIONS) === true,
    };
};

const mapDispatchToProps = {
    changeValue: (field, value) => change(`form-fidelisation`, field, value),
    getContract: (query) => getContract({query}),
    getTarifContract,
    postFidelisations
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { getContract, getTarifContract, postFidelisations } = dispatchProps;
    const { idContract, uri, uri_tarif, uriCreateFidelisation } = stateProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        onSubmit: (form) => postFidelisations({ query: _.replace(uriCreateFidelisation, '{id}', idContract), form }),
        load: () => getContract(_.replace(uri, '{id}', idContract)),
        postGetTarifContract: () => getTarifContract({query: _.replace(uri_tarif, '{id}', idContract)}),
    };
};

const CreateFidelisationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'form-fidelisation',
        validate,
    }),
)(CreateFidelisation);

export default CreateFidelisationContainer;
