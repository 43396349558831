import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../Commun/Input/Text/TextFieldRedux";
import {normalizeInteger} from "../../../../../../utils/normalizeInputs";
import CheckboxFieldRedux from "../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import Spinner from "../../../../../Commun/Spinner/Spinner";

const EditProductForm = (props) => {
    const {handleSubmit, loading} = props

    return (
        <form onSubmit={handleSubmit} className={'form-content'}>
            <div className="row">
                <div className="col">
                    <Field
                        name='taux_com_def'
                        component={TextFieldRedux}
                        label={'Taux de commission par produit par défaut'}
                        normalize={normalizeInteger}
                    />
                </div>
                <div className="col">
                    <Field
                        name='taux_com_max'
                        component={TextFieldRedux}
                        label={'Taux de commission par produit maximum'}
                        normalize={normalizeInteger}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field
                        name='montant_fa_def'
                        component={TextFieldRedux}
                        label={'Montant de frais d’acte par produit par défaut'}
                        normalize={normalizeInteger}
                    />
                </div>
                <div className="col">
                    <Field
                        name='montant_fa_max'
                        component={TextFieldRedux}
                        label={'Montant de frais d’acte par produit maximum'}
                        normalize={normalizeInteger}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field
                        name='montant_fr_def'
                        component={TextFieldRedux}
                        label={'Montant de frais récurrent par produit par défaut'}
                        normalize={normalizeInteger}
                    />
                </div>
                <div className="col">
                    <Field
                        name='montant_fr_max'
                        component={TextFieldRedux}
                        label={'Montant de frais récurrent par produit maximum'}
                        normalize={normalizeInteger}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field
                        component={CheckboxFieldRedux}
                        name={'actif'}
                        label={`Activé`}
                        type={'checkbox'}
                    />
                </div>
            </div>

            {
                loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                    <button className={'btn btn-primary d-block'} data-test-id="avenant_simple_btn_submit"
                            type={'submit'}>
                        Editer
                    </button>
            }

        </form>
    );
};

export default EditProductForm;