import {SET_PRODUCT} from "../../actions/app/products/product.actions";

export const productReducer = (product = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_PRODUCT) {
        return payload
    } else {
        return product
    }
}
