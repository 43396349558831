import React, {useEffect, useState} from 'react';
import './nouveau_devis.scss'
import {getCourtierLink, getCourtiers, getCourtiersAppex} from "../../../../../utils/function";
import _ from "lodash";
import Autocomplete from "../../../../Commun/Input/Autocomplete/Autocomplete";
import Spinner from "../../../../Commun/Spinner/Spinner";

const NouveauDevisCourtier = (props) => {
    const [liens, setLiens] = useState({velo: null, trottinette: null, livraison: null, autres: null})
    const [courtiers, setCourtiers] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            const arrayCourtiers = await getCourtiersAppex()
            const listCourtiers = _.orderBy(arrayCourtiers, ['name'], ['asc']).map(c => ({
                value: c.code_belair,
                label: c.code_belair + ' - ' + c.raison_sociale + ' - ' + c.codePostal + ' - ' + c.phone
            }));
            setCourtiers(listCourtiers)
        }
        fetchData();
    }, [])

    const handleChange = async (value) => {
        setLiens(null)
        const links = await getCourtierLink(value)
        setLiens({
            velo: links['velo'] ?? null,
            livraison: links['livraison'] ?? null,
            trottinette: links['trottinette'] ?? null,
            autres: links['all'] ?? null
        });
    }

    const ICON_MAPPING = {
        autres: { class: 'icone-moto', label: '2-roues & Quad' },
        velo: { class: 'icone-velo', label: 'Vélo' },
        livraison: { class: 'icone-livraison', label: 'Livraison' },
        trottinette: { class: 'icone-trottinette', label: 'Trottinette' }
    }

    return (
        <div className={'navigation-devis container'}>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary title-task'}>Nouveau devis</h1>
                    {courtiers ?
                        <Autocomplete
                            label={'Choix de l\'apporteur du devis'}
                            placeholder={'Choix de l\'apporteur'}
                            data={courtiers}
                            input={{onChange: (e) => handleChange(e)}}
                            meta={{ touched: null, error: null}}
                        />
                        : <div className="text-center">
                            <Spinner />
                        </div>
                    }
                    <div className="row header-row">
                        {liens ? <>
                            {Object.keys(ICON_MAPPING).map(key => {
                                return liens[key] !== null && (
                                    <div key={key} className={'col-12 col-md-6 col-xl-3 mb-4'}>
                                        <a href={liens[key]} target={'_blank'} rel="noopener noreferrer"
                                           className={`bloc-icon d-block text-center py-5 px-2`}>
                                            <div className={`icone ${ICON_MAPPING[key].class}`}/>
                                            <div className={'d-block mt-4'}>
                                                <span className={'font-weight-bold'}>{ICON_MAPPING[key].label}</span>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                            </>
                            : <div className="text-center">
                                <Spinner />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NouveauDevisCourtier;
