export const PRODUCT = '[Produit]'

export const GET_PRODUCT = `${PRODUCT} Get`
export const SET_PRODUCT = `${PRODUCT} Set`
export const POST_UPDATE_PRODUCT = `${PRODUCT} Post update`

export const getProduct = ({query}) => ({
    type: GET_PRODUCT,
    payload: {
        data: query
    }
})

export const setProduct = ({product}) => ({
    type: SET_PRODUCT,
    payload: {
        data: product
    },
    meta: {
        entity: PRODUCT
    }
})

export const postUpdateProduct = ({query, form}) => ({
    type: POST_UPDATE_PRODUCT,
    payload: {
        data: query,
        body: (form)
    }
})
